<script setup>
const router = useRouter()
const { $gtmTracking } = useNuxtApp()

function becomeMemberClicked() {
  $gtmTracking.becomeMemberClicked('BecomeMemberSection')
  router.push('/aanmelden')
}
</script>

<template>
  <div class="become-member-banner pt-72 pb-72">
    <div class="container text-center">
      <div class="row d-flex justify-content-center">
        <div cols="12" md="10" class="col-12 col-md-10">
          <h2 class="mb-16">
            {{ $label('common.becomeMemberNow.home.title') }}
          </h2>

          <p class="mb-48">
            {{ $label('common.becomeMemberNow.home.subtitle') }}
          </p>

          <p class="become-member-banner__description mb-32">
            {{ $label('common.becomeMemberNow.home.description') }}
          </p>

          <BaseButton
            data-testid="become-member-btn"
            class="mb-32"
            variant="call-to-action"
            @click.prevent="becomeMemberClicked"
          >
            {{ $label('common.becomeMemberNow.home.button') }}
          </BaseButton>

          <p class="mb-0 text-small fst-italic">
            {{ $label('common.becomeMemberNow.home.description2') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.become-member-banner {
  background-color: $grey-50;

  &__description {
    color: $grey-900;
  }
}

@include media-breakpoint-down(md) {
  button {
    width: 100%;
  }
}
</style>
